import { Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useNavigate, useParams } from "react-router-dom";
import {
  QuizSolutionButton,
  SecondaryButton,
} from "../../../components/styled/Button.styled";
import { StudentPanelWhiteContainer } from "../../../components/styled/Container.styled";
import { IQuizzes } from "../../../types/quizzes";
import { IMyGroup } from "../../../types/studentPanelTypes/studentMyGroupType";

type QuizzesProps = {
  data?: IMyGroup;
};
function Quizzes(props: QuizzesProps) {
  const { data } = props;
  const { courseId } = useParams();
  const navigate = useNavigate();
  const columns: ColumnsType<IQuizzes> = [
    {
      key: "quizName",
      title: (
        <div>
          <Typography.Text>Quiz Name</Typography.Text>
        </div>
      ),
      dataIndex: "quizName",
    },
    {
      key: "quizMode",
      title: (
        <div>
          <Typography.Text> Quiz Mode</Typography.Text>
        </div>
      ),

      dataIndex: "quizMode",
    },
    {
      key: "courseName",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
        </div>
      ),
      render: (courses) => courses[0].name,
      dataIndex: "courses",
    },
    {
      key: "numberOfQuestions",
      title: (
        <div>
          <Typography.Text>No. of Questions</Typography.Text>
        </div>
      ),
      dataIndex: "numberOfQuestions",
    },
    {
      key: "date",
      title: (
        <div>
          <Typography.Text>Date</Typography.Text>
        </div>
      ),

      dataIndex: "dateOfAppearing",
    },
    {
      render: (_, record) =>
        !!record.submitQuizId ? (
          <QuizSolutionButton onClick={() => viewQuiz(record.submitQuizId)}>
            View Quiz
          </QuizSolutionButton>
        ) : (
          <SecondaryButton onClick={() => takeQuiz(record.id)}>
            Take Quiz
          </SecondaryButton>
        ),
    },
  ];

  const takeQuiz = (id: number) => {
    navigate(`take-quiz/${id}`);
  };

  const viewQuiz = (id: number) => {
    navigate(`view-quiz/${id}`);
  };

  return (
    <StudentPanelWhiteContainer>
      <Table<IQuizzes>
        rowKey="id"
        columns={columns}
        dataSource={data?.quizzes}
      />
    </StudentPanelWhiteContainer>
  );
}

export default Quizzes;
