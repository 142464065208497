import { Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import FileTypeIcon from "../../../components/resource/FileTypeIcon";
import { PrimaryViewButton } from "../../../components/styled/Button.styled";
import { StudentPanelWhiteContainer } from "../../../components/styled/Container.styled";
import { IQuestionType } from "../../../types/questionType";
import { IResource } from "../../../types/resources";
import { ISection } from "../../../types/section";
import { IMyGroup } from "../../../types/studentPanelTypes/studentMyGroupType";

type ResourceProps = {
  data?: IMyGroup;
};

function Resource(props: ResourceProps) {
  const { data } = props;
  const navigate = useNavigate();
  const openResourceDetails = (id: number) => {
    navigate(`view-resource/${id}`);
  };
  const columns: ColumnsType<IResource> = [
    {
      key: "resourceIcon",
      dataIndex: "resourceType",
      render: (resourceType) => <FileTypeIcon resourceType={resourceType} />,
    },
    {
      key: "resourceName",
      title: (
        <div>
          <Typography.Text>Resource Name</Typography.Text>
        </div>
      ),
      dataIndex: "name",
    },
    {
      key: "course",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
        </div>
      ),
      render: (sections) =>
        sections.map((section: ISection) => section.courseName).join(","),

      dataIndex: "sections",
    },
    {
      key: "section",
      title: (
        <div>
          <Typography.Text>Section</Typography.Text>
        </div>
      ),
      render: (sections) =>
        sections.map((section: ISection) => section.name).join(","),
      dataIndex: "sections",
    },

    {
      key: "questionType",
      title: (
        <div>
          <Typography.Text>Question Type</Typography.Text>
        </div>
      ),
      render: (lessons) =>
        lessons.map((lesson: IQuestionType) => lesson.name).join(","),
      dataIndex: "lessons",
    },

    {
      key: "viewResource",
      render: (_, record) => (
        <PrimaryViewButton onClick={() => openResourceDetails(record.id)}>
          View Resource
        </PrimaryViewButton>
      ),
    },
  ];
  return (
    <>
      <StudentPanelWhiteContainer>
        <Table<IResource>
          rowKey="id"
          columns={columns}
          dataSource={data?.resources}
        />
      </StudentPanelWhiteContainer>
    </>
  );
}

export default Resource;
