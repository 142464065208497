import { Col, Progress, Row, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IQuizzes } from "../../../types/quizzes";
import {
  PrimaryButton,
  QuizSolutionButton,
  SecondaryButton,
} from "../../../components/styled/Button.styled";

import pageSize from "../../../constants/pageSize";
import { StudentPanelWhiteContainer } from "../../../components/styled/Container.styled";
import { useGetAllStudentQuizQuery } from "../../../features/studentPanelFeatures/StudentQuiz/studentQuizzes";
import { ICourse } from "../../../types/course";
import { ISection } from "../../../types/section";
import { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import { setStudentBreadcrumb } from "../../../features/ui/uiSlice";

function Quizzes() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const navigate = useNavigate();
  const { courseId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading, isFetching } = useGetAllStudentQuizQuery({
    pageSize,
    id: idFromLocalStorage,
    courseId,
    currentPage,
  });

  dispatch(setStudentBreadcrumb([`${pathname.split("/")[3]}`]));
  const columns: ColumnsType<IQuizzes> = [
    {
      key: "quizName",
      ellipsis: true,
      title: (
        <div>
          <Typography.Text>Quiz Name</Typography.Text>
        </div>
      ),
      dataIndex: "quizName",
    },
    {
      key: "quizMode",
      title: (
        <div>
          <Typography.Text> Quiz Mode</Typography.Text>
        </div>
      ),

      dataIndex: "quizMode",
    },
    {
      key: "courses",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
        </div>
      ),

      dataIndex: "courses",
      render: (courses: ICourse[]) => courses.map((item: any) => item?.name),
    },
    {
      key: "section",
      title: (
        <div>
          <Typography.Text>Section</Typography.Text>
        </div>
      ),

      dataIndex: "sections",
      render: (section: ISection[]) => section.map((item: any) => item?.name),
    },
    {
      key: "quizTime",
      title: (
        <div>
          <Typography.Text>Duration</Typography.Text>
        </div>
      ),
      dataIndex: "quizTime",
      render: (_, record) => {
        return `${record?.quizTime !== null ? record.quizTime : "--"} min`;
      },
    },
    {
      title: (
        <div>
          <Typography.Text>% Completed</Typography.Text>
        </div>
      ),
      key: "progress",
      dataIndex: "progress",
      render: (_, record) => {
        return (
          <Progress
            percent={record.quizProgress}
            strokeColor="#44CA15"
            className="mb-0"
          />
        );
      },
    },
    {
      render: (_, record) =>
        !!record.submitQuizId ? (
          <QuizSolutionButton onClick={() => viewQuiz(record.submitQuizId)}>
            View Quiz
          </QuizSolutionButton>
        ) : (
          <SecondaryButton onClick={() => takeQuiz(record.id)}>
            Take Quiz
          </SecondaryButton>
        ),
    },
  ];

  const takeQuiz = (id: number) => {
    navigate(`take-quiz/${id}`);
  };

  const viewQuiz = (id: number) => {
    navigate(`view-quiz/${id}`);
  };

  const handleQuiz = () => {
    navigate("create-quiz");
  };

  return (
    <>
      <StudentPanelWhiteContainer>
        <Table<IQuizzes>
          className="d-table"
          rowKey="id"
          loading={isLoading || isFetching}
          columns={columns}
          dataSource={data?.data.content}
          pagination={{
            pageSize,
            current: currentPage,
            total: data?.data.totalElements,
            onChange(page) {
              setCurrentPage(page);
            },
          }}
        />
        <Col span={24}>
          <Row justify={"center"}>
            <PrimaryButton onClick={handleQuiz}>
              Create Custom Quiz
            </PrimaryButton>
          </Row>
        </Col>
      </StudentPanelWhiteContainer>
    </>
  );
}

export default Quizzes;
