import { Checkbox } from "antd";

type ProgressCheckboxProps = {
  topicId?: number;
  resourceId?: number;
  quizId?: number;
  isChecked?: boolean;
};
function ProgressCheckbox(props: ProgressCheckboxProps) {
  const { isChecked } = props;
  return <Checkbox disabled checked={isChecked} />;
}

export default ProgressCheckbox;
