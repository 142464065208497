import { Rule } from "rc-field-form/lib/interface";
import { useMemo } from "react";
import { FormInstance } from "antd/es/form";
import { RootState } from "../../../app/store";
import BorderedCheckboxes from "../../quizzes/BorderedCheckboxes";
import { ITopicSubtopicData, IValueLabel } from "../../../types/common";

import { useAppSelector } from "../../../app/hooks";
import { useGetTopicByLessonIdQuery } from "../../../features/studentPanelFeatures/StudentQuiz/studentQuizzes";
import { ITopic } from "../../../types/topic";
import TreeDropdown from "../../quizzes/TreeDropdown";

type IProps = {
  label: string;
  name: string;
  rules?: Rule[];
  form: FormInstance;
  dependency?: "questionTypeId" | "lessonId";
  disabled?: boolean;
};

function TopicCheckbox(props: IProps) {
  const { form, dependency = "questionTypeId", disabled } = props;

  const selectedStudentQuestionType = useAppSelector(
    (state: RootState) => state.ui.selectedStudentQuestionType
  );

  const { data, isFetching, isLoading } = useGetTopicByLessonIdQuery(
    selectedStudentQuestionType || form?.getFieldValue(dependency)
  );

  const getOptions = (
    topicsData: ITopic[] | undefined
  ): ITopicSubtopicData[] | undefined => {
    return topicsData?.map(({ id, name, subTopics }) => ({
      key: id,
      title: name,
      children: subTopics && subTopics.length > 0 ? getOptions(subTopics) : [],
    }));
  };

  const mappedData: ITopicSubtopicData[] | undefined = useMemo(
    () => getOptions(data?.data),
    [data]
  );

  return (
    <TreeDropdown
      treeData={mappedData}
      disabled={disabled}
      {...props}
      isFetching={isFetching}
      isLoading={isLoading}
    />
  );
}

export default TopicCheckbox;
