import { Col, Modal, Row, Spin, Typography } from "antd";

type ModalProps = {
  openModal: boolean;
};
const ModalSubmitQuiz = ({ openModal }: ModalProps) => {
  return (
    <>
      <Modal
        open={openModal}
        centered
        width={"100vw"}
        closable={false}
        footer={null}
        bodyStyle={{ height: "90vh" }}
      >
        <Row align={"middle"} style={{ height: "100%" }} justify={"center"}>
          <Col span={2}>
            <Spin size="large" />
          </Col>
          <Typography.Text>Submitting quiz...</Typography.Text>
        </Row>
      </Modal>
    </>
  );
};

export default ModalSubmitQuiz;
