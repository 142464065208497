import { Alert, Col, Progress, Row, Badge, Divider, Space } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  PrimaryButton,
  ViewSolutionButton,
} from "../../../components/styled/Button.styled";
import { StudentPanelWhiteContainer } from "../../../components/styled/Container.styled";
import {
  commonStrokeColor,
  skippedStrokeColor,
  wrongStrokeColor,
} from "../../../constants/commonColors";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import { setStudentBreadcrumb } from "../../../features/ui/uiSlice";

function SubmitQuiz() {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const submitData = state?.data;
  const { courseId } = useParams();

  const quizProgress = {
    total: submitData?.data.totalQuestions,
    wrong: submitData?.data.wrongAnswerCount,
    correct: submitData?.data.correctAnswerCount,
    skip: submitData?.data.skippedAnswerCount,
  };

  const ViewSolution = (id: number) => {
    navigate(`/course/${courseId}/my-group/view-quiz/${id}`);
  };

  const BackToGroups = (courseId: string | undefined) => {
    navigate(`/course/${courseId}/my-group`);
  };

  const dispatch = useDispatch();

  dispatch(
    setStudentBreadcrumb([
      `${pathname.split("/")[3]}/${pathname.split("/")[4]}`,
    ])
  );
  return (
    <>
      <StudentPanelWhiteContainer>
        <Alert
          banner
          showIcon={false}
          message={"Thanks for taking the quiz!"}
          style={{
            backgroundColor: "#fff",
            textAlign: "center",
            fontSize: 28,
            color: "#201E43",
          }}
        />
        <div className="text-blue">
          <Row>
            <Col span={12}>
              <Row>
                <Col span={10} offset={6} className="count-text-font-size">
                  <br />
                  <br />
                  <br />
                  <Row>
                    <Col>
                      <span> Quiz Name :</span>
                      <span> {submitData?.data.quizName} </span>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <span> Total Question :</span>
                      <span> {submitData?.data.totalQuestions}</span>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <span> Quiz Duration :</span>
                    <span> {submitData?.data.quizTime} mins</span>
                  </Row>
                  <br />
                  <Row>
                    <span> Time Taken :</span>
                    <span> {submitData?.data.timeTaken} mins</span>
                  </Row>
                  <br />
                  <Row>
                    <span> Test date :</span>
                    <span> {submitData?.data.dateOfAppearing}</span>
                  </Row>
                  <br />
                  <br />
                </Col>
              </Row>
            </Col>
            <Col span={1}>
              <Divider type="vertical" style={{ height: "100%" }} />
            </Col>

            <Col span={11}>
              <Row>
                <Col span={10} offset={3} className="count-text-font-size">
                  <br />
                  <Row>
                    <Space size={30}>
                      <Col span={10}>
                        <Progress
                          type="circle"
                          strokeColor={commonStrokeColor}
                          percent={Math.round(
                            (quizProgress.correct /
                              submitData?.data.totalQuestions) *
                            100
                          )}
                          format={(percent) => `${percent}% Correct`}
                        />
                      </Col>
                      <Col span={8}>
                        <Progress
                          type="circle"
                          strokeColor={wrongStrokeColor}
                          percent={Math.round(
                            (quizProgress.wrong /
                              submitData?.data.totalQuestions) *
                            100
                          )}
                          status="exception"
                          format={(percent) => `${percent}% Wrong`}
                        />
                      </Col>
                      <Col span={8}>
                        <Progress
                          type="circle"
                          strokeColor={skippedStrokeColor}
                          percent={Math.round(
                            (quizProgress.skip /
                              submitData?.data.totalQuestions) *
                            100
                          )}
                          format={(percent) => `${percent}% Skipped`}
                        />
                      </Col>
                    </Space>
                  </Row>
                  <br />
                  <Row>
                    <span>
                      <Badge status="success" />
                      &nbsp; Correct Answer :
                    </span>
                    <span>{quizProgress.correct}</span>
                  </Row>
                  <br />
                  <Row>
                    <span>
                      <Badge status="error" /> &nbsp; Incorrect Answer :
                    </span>
                    <span>{quizProgress.wrong}</span>
                  </Row>
                  <br />
                  <Row>
                    <span>
                      <Badge status="default" />
                      &nbsp; Skipped Answer :
                    </span>
                    <span>{quizProgress.skip}</span>
                  </Row>
                  <br />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col span={12}>
              <span>
                <ViewSolutionButton
                  style={{ left: "62%" }}
                  onClick={() => ViewSolution(submitData?.data.id)}
                >
                  View Solution
                </ViewSolutionButton>
              </span>
            </Col>
            <Col span={12}>
              <span>
                <PrimaryButton
                  style={{ left: "18%" }}
                  onClick={() => BackToGroups(courseId)}
                >
                  Go back to Groups
                </PrimaryButton>
              </span>
            </Col>
          </Row>
        </div>
      </StudentPanelWhiteContainer>
    </>
  );
}

export default SubmitQuiz;
