import secureLocalStorage from "react-secure-storage";
import { StudentPanelWhiteContainer } from "../../../components/styled/Container.styled";
import { useGetAllMyGroupByStudentIdQuery } from "../../../features/studentPanelFeatures/StudentMyGroup/studentMyGroupApiSlice";
import MyGroupContent from "./MyGroupContent";
import { useLocation, useParams } from "react-router-dom";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
import { setStudentBreadcrumb } from "../../../features/ui/uiSlice";

function MyGroup() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const { courseId } = useParams();
  const { data, isLoading, isFetching } = useGetAllMyGroupByStudentIdQuery({
    courseId,
    id: idFromLocalStorage,
  });

  dispatch(setStudentBreadcrumb([`${pathname.split("/")[3]}`]));
  return (
    <>
      <StudentPanelWhiteContainer>
        {!isLoading && !isFetching ? (
          <MyGroupContent data={data?.data[0]} />
        ) : (
          <Spin />
        )}
      </StudentPanelWhiteContainer>
    </>
  );
}

export default MyGroup;
