import { Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { ViewDetailsButton } from "../../../components/styled/Button.styled";
import { useGetAllStudentViewQuizQuery } from "../../../features/studentPanelFeatures/StudentQuiz/studentQuizzes";
import pageSize from "../../../constants/pageSize";
import { IStudentQuizProgressType } from "../../../types/studentPanelTypes/studentQuizProgressType";
import { useNavigate,useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

function QuizProgress() {
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const navigate = useNavigate();
  const { courseId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading } = useGetAllStudentViewQuizQuery({
    idFromLocalStorage,
    currentPage,
    pageSize,
    courseId
  });

  const columns: ColumnsType<IStudentQuizProgressType> = [
    {
      key: "name",
      width: "15%",
      ellipsis: true,
      dataIndex: "quizName",
    },
    {
      key: "date",
      width: "10%",
      dataIndex: "dateOfAppearing",
      render: (_, record) => (
        <Typography.Text code>{record.dateOfAppearing}</Typography.Text>
      ),
    },
    {
      key: "type",
      width: "12%",
      dataIndex: "quizMode",
      render: (_, record) => (
        <Typography.Text code>{record.quizMode}</Typography.Text>
      ),
    },
    {
      key: "questions",
      dataIndex: "totalQuestions",
      render: (_, record) => (
        <Typography.Text code>
          {record.totalQuestions} Questions
        </Typography.Text>
      ),
    },
    {
      key: "correct",
      dataIndex: "correctAnswerCount",
      render: (_, record) => (
        <Typography.Text code style={{ color: "green" }}>
          {record.correctAnswerCount} Correct
        </Typography.Text>
      ),
    },
    {
      key: "wrong",
      dataIndex: "wrongAnswerCount",
      render: (_, record) => (
        <Typography.Text code style={{ color: "red" }}>
          {record.wrongAnswerCount} Wrong
        </Typography.Text>
      ),
    },
    {
      key: "skip",
      dataIndex: "skippedAnswerCount",
      render: (_, record) => (
        <Typography.Text code>
          {record.skippedAnswerCount} Skipped
        </Typography.Text>
      ),
    },
    {
      key: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openDetails(record.id)} />
      ),
    },
  ];
  const openDetails = (id: number) => {
    navigate(`view-quiz/${id}`);
  };
  return (
    <>
      <Table<IStudentQuizProgressType>
        columns={columns}
        dataSource={data?.data?.content}
        loading={isLoading}
        pagination={{
          pageSize,
          current: currentPage,
          total: data?.data.totalElements,
          onChange(page) {
            setCurrentPage(page);
          },
        }}
      />
    </>
  );
}

export default QuizProgress;
