import { Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useNavigate, useParams } from "react-router-dom";
import { ViewDetailsButton } from "../../../components/styled/Button.styled";
import { useState } from "react";
import pageSize from "../../../constants/pageSize";
import { useGetBlackBookResourceQuery } from "../../../features/studentPanelFeatures/StudentBlackBook/blackBookApiSlice";
import { IBlackBook } from "../../../types/studentPanelTypes/blackBookType";
import { ITopic } from "../../../types/topic";
import { ISection } from "../../../types/section";
import secureLocalStorage from "react-secure-storage";

function Video() {
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { courseId } = useParams();
  const resourceType = "MP4";
  const { data, isFetching, isLoading } = useGetBlackBookResourceQuery({
    idFromLocalStorage,
    currentPage,
    pageSize,
    resourceType,
    courseId,
  });

  const columns: ColumnsType<IBlackBook> = [
    {
      key: "videoName",
      title: (
        <div>
          <Typography.Text>Video Name</Typography.Text>
        </div>
      ),
      dataIndex: "name",
    },
    {
      key: "courseName",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
        </div>
      ),
      render: (courses) => courses[0]?.name,
      dataIndex: "courses",
    },
    {
      key: "section",
      title: (
        <div>
          <Typography.Text>Section</Typography.Text>
        </div>
      ),
      render: (sections) =>
        sections.map((section: ISection) => section.name).join(","),
      dataIndex: "sections",
    },
    {
      key: "topic",
      title: (
        <div>
          <Typography.Text> Topic</Typography.Text>
        </div>
      ),
      render: (topics) => topics.map((topic: ITopic) => topic.name).join(","),
      dataIndex: "topics",
    },
    {
      key: "date&time",
      title: (
        <div>
          <Typography.Text>Date & Time</Typography.Text>
        </div>
      ),
      render: (dateTime) => (
        <>
          {`${dateTime?.dayOfMonth}/${dateTime?.monthValue}/${dateTime?.year}`}
          &nbsp;,&nbsp;
          {`${dateTime?.hour}:${dateTime?.minute}`}
        </>
      ),
      dataIndex: "dateTime",
    },
    {
      key: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openDetails(record.id)} />
      ),
    },
  ];

  const openDetails = (id: number) => {
    navigate(`view-resource/${id}`);
  };

  return (
    <>
      <Table<IBlackBook>
        dataSource={data?.data?.content}
        rowKey="id"
        columns={columns}
        loading={isFetching || isLoading}
        pagination={{
          pageSize,
          current: currentPage,
          total: data?.data?.totalElements,
          onChange(page) {
            setCurrentPage(page);
          },
        }}
      />
    </>
  );
}

export default Video;
