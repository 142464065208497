import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";
import ResourceFileViewer from "../pages/InternalCourses/Preview/ResourceFileViewer";
import QuizViewer from "../pages/InternalCourses/Preview/QuizViewer";
import CourseIntroViewer from "../pages/InternalCourses/Preview/CourseIntroViewer";

import StudentDashboard from "../pages/studentPages/StudentDashboard";
import StudentPanelLayout from "../components/layout/StudentPanelLayout";
import StudentProfile from "../pages/studentPages/studentProfile/StudentProfile";

//commonRoutes
import ForgotPassword from "../pages/login/ForgotPassword";
import StudentLogin from "../pages/studentPages/StudentLogin/StudentLogin";
import NotFoundPage from "../pages/NotFoundPage";
import CreateNewPassword from "../pages/login/CreateNewPassword";

// Student Profile

import StudentPanelCourseLayout from "../components/layout/StudentPanelCourseLayout";
//quiz-student
import Quiz from "../pages/studentPages/Quizzes/Quizzes";
import AddQuiz from "../pages/studentPages/Quizzes/AddQuiz";
//Error-Log
import ErrorLog from "../pages/studentPages/errorLog/ErrorLog";
import ViewQuestions from "../pages/studentPages/errorLog/ViewQuestions";
import MyLearningIntroduction from "../pages/studentPages/MyLearning/MyLearningIntroduction";
import MyLearningView from "../components/myLearning/MyLearningView";

import StudentQueries from "../pages/studentPages/StudentQueries/StudentQueries";

import BlackBook from "../pages/studentPages/BlackBook/BlackBook";
import MyGroup from "../pages/studentPages/MyGroup/MyGroup";

import MyGroupResource from "../pages/studentPages/MyGroup/MyGroupResource";

import StudentProgressAnalytics from "../pages/studentPages/Analytics/StudentProgressAnalytics";
import ViewBlackbookQuestion from "../pages/studentPages/BlackBook/ViewBlackbookQuestion";
import StudentNotification from "../pages/studentPages/StudentNotification";

import TakeQuiz from "../pages/studentPages/Quizzes/TakeQuiz";
import ViewQuiz from "../pages/studentPages/Quizzes/ViewQuiz";
import SubmitQuiz from "../pages/studentPages/Quizzes/SubmitQuiz";

import GroupTakeQuiz from "../pages/studentPages/MyGroup/GroupTakeQuiz";
import GroupViewQuiz from "../pages/studentPages/MyGroup/GroupViewQuiz";
import GroupSubmitQuiz from "../pages/studentPages/MyGroup/GroupSubmitQuiz";
import { isStudent } from "../constants/localStorage";
import AnalyticsSubmitQuiz from "../pages/studentPages/Analytics/AnalyticsSubmitQuiz";
import ViewAnalyticsQuiz from "../pages/studentPages/Analytics/ViewAnalyticsQuiz";

const StudentRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:id" element={<CreateNewPassword />} />
        <Route path="/login" element={<StudentLogin />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="" element={<StudentPanelLayout />}>
            <Route index element={<StudentDashboard />} />
            <Route
              path="course/:courseId"
              element={<StudentPanelCourseLayout />}
            >
              <Route index element={<Navigate to="my-learning" />} />
              <Route path="my-learning" element={<MyLearningIntroduction />} />
              <Route path="quizzes">
                <Route index element={<Quiz />} />
                <Route path="create-quiz" element={<AddQuiz />} />
              </Route>

              {/* error log */}
              <Route path="error-log">
                <Route index element={<ErrorLog />} />
              </Route>

              {/* blackbook */}
              <Route path="blackbook">
                <Route index element={<BlackBook />} />
              </Route>

              {/*student queries */}
              <Route path="query" element={<StudentQueries />} />

              {/* my-group */}
              <Route path="my-group">
                <Route index element={<MyGroup />} />
              </Route>

              {/* analytics */}
              <Route path="analytics" element={<StudentProgressAnalytics />} />
            </Route>

            {/* Routes outside sidebar */}
            <Route path="student-profile" element={<StudentProfile />} />
            <Route path="/view-question" element={<ViewQuestions />} />
            <Route path="/view-quiz/:id" element={<ViewQuiz />} />
            <Route path="/view-question/:id" element={<ViewQuestions />} />
            <Route path="/submit" element={<SubmitQuiz />} />

            <Route
              path="/course/:courseId/start-learning/view-quiz/:id"
              element={<ViewQuiz />}
            />
            <Route
              path="/course/:courseId/start-learning/submitQuiz"
              element={<SubmitQuiz />}
            />

            {/* For learning screens */}
            <Route
              path="/course/:courseId/start-learning"
              element={<MyLearningView />}
            >
              <Route
                path="courseIntro/:courseIntroId"
                element={<CourseIntroViewer />}
              />
              <Route
                path="courseIntroResource/:resourceId"
                element={<ResourceFileViewer />}
              />
              <Route
                path="topic/:topicId/quiz/:quizId"
                element={<QuizViewer />}
              />
              <Route
                path="topic/:topicId/resource/:resourceId"
                element={<ResourceFileViewer />}
              />
            </Route>

            {/* notification */}
            <Route
              path="student-notification"
              element={<StudentNotification />}
            />

            {/* my-group routes outside sidebar */}
            <Route
              path="course/:courseId/my-group/take-quiz/:id"
              element={<GroupTakeQuiz />}
            />
            <Route
              path="course/:courseId/my-group/view-quiz/:id"
              element={<GroupViewQuiz />}
            />
            <Route
              path="course/:courseId/my-group/submitQuiz"
              element={<GroupSubmitQuiz />}
            />
            <Route
              path="/course/:courseId/my-group/view-resource/:resourceId"
              element={<MyGroupResource />}
            />

            {/* Quizzes routes outside sidebar */}
            <Route
              path="course/:courseId/quizzes/take-quiz/:id"
              element={<TakeQuiz />}
            />
            <Route
              path="course/:courseId/quizzes/view-quiz/:id"
              element={<ViewQuiz />}
            />
            <Route
              path="course/:courseId/quizzes/submitQuiz"
              element={<SubmitQuiz />}
            />

            {/* Errorlog routes outside sidebar */}
            <Route
              path="/course/:courseId/error-log/view-question/:questionId"
              element={<ViewQuestions />}
            />

            {/* Analytics routes outside sidebar */}
            <Route
              path="course/:courseId/analytics/view-quiz/:id"
              element={<ViewAnalyticsQuiz />}
            />
            <Route
              path="course/:courseId/analytics/submitQuiz"
              element={<AnalyticsSubmitQuiz />}
            />

            {/* blackbook routes outside sidebar */}
            <Route
              path="/course/:courseId/blackbook/view-resource/:resourceId"
              element={<MyGroupResource />}
            />
            <Route
              path="/course/:courseId/blackbook/view-blackbook-question/:questionId"
              element={<ViewBlackbookQuestion />}
            />
          </Route>
          <Route path="/take-quiz/:id" element={<TakeQuiz />} />
          <Route path="/takeGroup-quiz/:id" element={<GroupTakeQuiz />} />
          <Route
            path="/course/:courseId/start-learning/take-quiz/:id"
            element={<TakeQuiz />}
          />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default StudentRoutes;
