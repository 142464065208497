import { Select, Form } from "antd";
import { FormInstance, Rule } from "antd/es/form";
import { IValueLabel } from "../../../types/common";

type IProps = {
  label: string;
  name: string;
  rules?: Rule[];
  form: FormInstance;
};

const option: IValueLabel[] = [
  {
    value: "5",
    label: "5",
  },
  {
    value: "10",
    label: "10",
  },
  {
    value: "15",
    label: "15",
  },
  {
    value: "20",
    label: "20",
  },
];
function NumberOfQuestion(props: IProps) {
  return (
    <>
      <Form.Item {...props}>
        <Select options={option}  placeholder = "Select Number Of Question" />
      </Form.Item>
    </>
  );
}
export default NumberOfQuestion;
