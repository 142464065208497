import { Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import pageSize from "../../../constants/pageSize";
import { ISection } from "../../../types/section";

import { IStudentsByIdResponse } from "../../../types/studentPanelType/profileStudent";
import { ICourse } from "../../../types/course";

type AssignedCourseProps = {
  studentData?: IStudentsByIdResponse;
};
function AssignCourses(props: AssignedCourseProps) {
  const { studentData } = props;

  const columns: ColumnsType<ICourse> = [
    {
      key: "courseName",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
        </div>
      ),
      dataIndex: "name",
    },
    {
      key: "section",
      title: (
        <div>
          <Typography.Text>Section</Typography.Text>
        </div>
      ),
      render: (section: ISection[]) =>
        section?.map((section: ISection) => section?.name).join(","),
      dataIndex: "section",
    },
    {
      key: "groupName",
      dataIndex: "groupName",
      title: (
        <div>
          <Typography.Text>Group Name</Typography.Text>
        </div>
      ),
    },
    {
      key: "duration",
      dataIndex: "duration",
      title: (
        <div>
          <Typography.Text>Duration</Typography.Text>
        </div>
      ),
      render: (_, record) => `${record?.duration} min.`,
    },
  ];

  return (
    <>
      <Table<ICourse>
        rowKey="id"
        columns={columns}
        pagination={{
          pageSize,
        }}
        dataSource={studentData?.data?.assignCourse}
      />
    </>
  );
}

export default AssignCourses;
