import { Col, Divider, Row, Tabs } from "antd";
import Title from "antd/es/typography/Title";
import secureLocalStorage from "react-secure-storage";
import { StudentPanelWhiteContainer } from "../../../components/styled/Container.styled";
import { useGetBlackBookCountQuery } from "../../../features/studentPanelFeatures/StudentBlackBook/blackBookApiSlice";
import Notes from "./Notes";
import Pdf from "./Pdf";
import Ppt from "./Ppt";
import Question from "./Question";
import Video from "./Video";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import { setStudentBreadcrumb } from "../../../features/ui/uiSlice";
function BlackBook() {
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const { courseId } = useParams();
  const { data: countData } = useGetBlackBookCountQuery({
    id: idFromLocalStorage,
    courseId,
  });
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  dispatch(setStudentBreadcrumb([`${pathname.split("/")[3]}`]));
  const tabItems = [
    {
      label: "Video",
      key: "1",
      children: <Video></Video>,
    },
    {
      label: "PDF",
      key: "2",
      children: <Pdf></Pdf>,
    },
    {
      label: "Question",
      key: "3",
      children: <Question></Question>,
    },
    {
      label: "Notes",
      key: "4",
      children: <Notes></Notes>,
    },
    {
      label: "PPT",
      key: "5",
      children: <Ppt></Ppt>,
    },
  ];
  return (
    <StudentPanelWhiteContainer>
      <Row justify={"space-around"} className="blackbook-count-row">
        <Col className="p-1">
          <Row align={"middle"}>
            <Title level={3} className="m-0">
              {countData?.data?.totalVideos}
            </Title>
            &nbsp; &nbsp;
            <span>Total Videos</span>
          </Row>
        </Col>
        <Divider
          orientation="right"
          type="vertical"
          className="m-0 vertical-divider"
        />
        <Col className="p-1">
          <Row align={"middle"}>
            <Title level={3} className="m-0">
              {countData?.data?.totalPdf}
            </Title>
            &nbsp; &nbsp;
            <span>Total PDF</span>
          </Row>
        </Col>
        <Divider
          orientation="right"
          type="vertical"
          className="m-0 vertical-divider"
        />
        <Col className="p-1">
          <Row align={"middle"}>
            <Title level={3} className="m-0">
              {countData?.data?.totalQuestions}
            </Title>
            &nbsp; &nbsp;
            <span>Questions</span>
          </Row>
        </Col>
        <Divider
          orientation="right"
          type="vertical"
          className="m-0 vertical-divider"
        />
        <Col className="p-1">
          <Row align={"middle"}>
            <Title level={3} className="m-0">
              {countData?.data?.totalNotes}
            </Title>
            &nbsp; &nbsp;
            <span>Notes</span>
          </Row>
        </Col>
        <Divider className="horizontal-divider" />
      </Row>
      <Row className="mt-2">
        <Col span={24}>
          <Tabs items={tabItems} />
        </Col>
      </Row>
    </StudentPanelWhiteContainer>
  );
}

export default BlackBook;
