import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { useLazyGetStudentDashboardQuery } from "../../features/studentPanelFeatures/StudentDashboard/studentDashboard";
import { useSaveMyLearningProgressMutation } from "../../features/studentPanelFeatures/StudentMyLearning/myLearningApiSlice";
import { setStudentCourseProgressData } from "../../features/ui/uiSlice";
import { FaliureButton, SuccessButton } from "../styled/Button.styled";
import { useEffect } from "react";

function MarkAsCompleteButton({ isChecked, setMarkedData }: any) {
  const idFromLocalStorage = secureLocalStorage.getItem("id")?.toString() || null;
  const { topicId, resourceId, quizId, courseId } = useParams();
  const [saveMyLearningProgress, { isLoading }] = useSaveMyLearningProgressMutation();
  const itemId = resourceId ? resourceId : quizId;
  const [getData] = useLazyGetStudentDashboardQuery();
  // filter dashboard data to get course progress
  const dispatch = useDispatch();

  const onClick = async () => {
    await saveMyLearningProgress({
      studentId: idFromLocalStorage,
      studentProgress: [itemId],
      topicId: topicId,
    }).then((res: any) => {
      if (res) {
        setMarkedData({
          itemId: itemId,
          topicId: topicId
        });
      }
    });
    setTimeout(() => {
      getData(idFromLocalStorage).then((data: any) => {
        if (data.status === 'fulfilled') {
          dispatch(
            setStudentCourseProgressData(
              data?.data?.data?.courseMinimalDTOList?.filter((course: any) => {
                return `${course?.courseId}` === courseId;
              })[0]?.courseProgress
            )
          );
        }
      }).catch((error: any) => { console.log(error.message); });
    }, 5000);
  };
  return isChecked ? <SuccessButton disabled style={{ backgroundColor: "#c3dd7a", color: "white" }}>Completed</SuccessButton> : <FaliureButton loading={isLoading} onClick={onClick}>Mark as Completed</FaliureButton>;
}

export default MarkAsCompleteButton;
