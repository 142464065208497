import { Tabs } from "antd";
import { StudentPanelWhiteContainer } from "../../../components/styled/Container.styled";
import QuizProgress from "./QuizProgress";
import TopicWiseProgress from "./TopicWiseProgress";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { setStudentBreadcrumb } from "../../../features/ui/uiSlice";

function StudentProgressAnalytics() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  dispatch(setStudentBreadcrumb([`${pathname.split("/")[3]}`]));

  const tabItems = [
    {
      key: "1",
      label: "Topic Wise Progress",
      children: <TopicWiseProgress />,
    },
    {
      key: "2",
      label: "Quiz Progress",
      children: <QuizProgress />,
    },
  ];
  return (
    <>
      <StudentPanelWhiteContainer>
        <Tabs defaultActiveKey="1" items={tabItems} />
      </StudentPanelWhiteContainer>
    </>
  );
}

export default StudentProgressAnalytics;
