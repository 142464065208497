import { Link, useLocation, useParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { Row } from "antd";

const StudentBreadcrumbs = () => {
  const { pathname } = useLocation();
  const { courseId } = useParams();
  const pathsArray = pathname.split("/").slice(1);
  const breadcrumb = useAppSelector((state) => state.ui.studentBreadcrumb)
    .toString()
    .split("/");

  const isLast = breadcrumb.length - 1 === 1;
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return (
    <>
      {!pathsArray.includes("start-learning") ? (
        <>
          {!isLast &&
            breadcrumb.map((item, index) => (
              <span key={index}>
                {capitalizeFirstLetter(item).concat(" >")}
              </span>
            ))}
          {isLast && (
            <Row align={"middle"}>
              <Link
                to={`/course/${courseId}/${breadcrumb[0]}`}
                style={{ color: "black", textDecorationLine: "underline" }}
              >
                {capitalizeFirstLetter(breadcrumb[0]).concat(" >")}
              </Link>
              {capitalizeFirstLetter(breadcrumb[1])}
            </Row>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default StudentBreadcrumbs;
