import {
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Category from "../../../components/selectFormOptions/Category";
import DifficultyLevel from "../../../components/selectFormOptions/DifficultyLevel";
import SelectQuestionTypeOptions from "../../../components/selectFormOptions/StudentPanelSelectOption/SelectQuestionTypeOptions";
import SelectSectionOptions from "../../../components/selectFormOptions/StudentPanelSelectOption/SelectSectionOptions";
import NumberOfQuestion from "../../../components/selectFormOptions/StudentPanelSelectOption/NumberOfQuestion";
import QuizMode from "../../../components/selectFormOptions/StudentPanelSelectOption/QuizMode";

import { StudentPanelWhiteContainer } from "../../../components/styled/Container.styled";
import { useGetAllMyLearningByCourseIdQuery } from "../../../features/studentPanelFeatures/StudentMyLearning/myLearningApiSlice";
import TopicCheckbox from "../../../components/selectFormOptions/StudentPanelSelectOption/TopicCheckbox";
import secureLocalStorage from "react-secure-storage";

function CreateCustomQuiz() {
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const [form] = Form.useForm();
  const { courseId } = useParams();

  const [value, setValue] = useState(1);

  const { data } = useGetAllMyLearningByCourseIdQuery({
    id: idFromLocalStorage,
    courseId: courseId,
  });

  const course = data?.data
    .map((item) => item.course)
    .map((item) => item.name)
    .toString();

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const quizModeStatus = Form.useWatch("quizMode", form);
  const [quizTimeRequired, setQuizTimeRequired] = useState(false);

  useEffect(() => {
    quizModeStatus === "TIME_BASED"
      ? setQuizTimeRequired(true)
      : setQuizTimeRequired(false);
  }, [quizModeStatus]);

  return (
    <>
      {!!data ? (
        <StudentPanelWhiteContainer>
          <Form
            form={form}
            name="mainForm"
            layout="vertical"
            initialValues={{
              quizQuestion: "Random Selection",
            }}
          >
            <Row gutter={[32, 32]}>
              <Col span={12}>
                <Row gutter={[32, 32]}>
                  <Col span={24}>
                    <Form.Item label="Course" name="courses">
                      <Input defaultValue={course} disabled />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Quiz Name"
                      name="quizName"
                      rules={[
                        { required: true, message: "Please enter quiz name" },
                      ]}
                    >
                      <Input placeholder="Enter Quiz Name" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <DifficultyLevel
                      label="Difficulty Level"
                      name="difficultyLevel"
                      rules={[
                        {
                          required: true,
                          message: "Please select difficulty level",
                        },
                      ]}
                      form={form}
                    />
                  </Col>

                  <Col span={24}>
                    <QuizMode
                      label="Quiz Mode"
                      name="quizMode"
                      rules={[
                        { required: true, message: "Please enter quiz mode" },
                      ]}
                      form={form}
                    />
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Quiz Questions"
                      name="quizQuestion"
                      rules={[
                        {
                          required: true,
                          message: "Please enter quiz questions",
                        },
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <NumberOfQuestion
                      label="Number of Questions"
                      name="numberOfQuestions"
                      rules={[
                        {
                          required: true,
                          message: "Please enter number of question",
                        },
                      ]}
                      form={form}
                    />
                  </Col>

                  <Col span={24}>
                    <SelectSectionOptions
                      label="Section"
                      name="sections"
                      rules={[
                        { required: true, message: "Please select section" },
                      ]}
                      form={form}
                    />
                  </Col>
                </Row>
              </Col>

              <Col span={12}>
                <Row gutter={[32, 32]}>
                  <Col span={24}>
                    <SelectQuestionTypeOptions
                      label="Question Type"
                      name="questionTypeId"
                      rules={[
                        {
                          required: true,
                          message: "Please select question type",
                        },
                      ]}
                      form={form}
                    />
                  </Col>
                  <Col span={24}>
                    <Category
                      label="Question Category"
                      name="questionCategory"
                      rules={[
                        {
                          required: true,
                          message: "Please select question category",
                        },
                      ]}
                      form={form}
                    />
                  </Col>
                  <Col span={24}>
                    <TopicCheckbox
                      form={form}
                      label="Topics"
                      name="topicIds"
                      rules={[
                        { required: true, message: "Please select topics" },
                      ]}
                    />
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Question to Used"
                      name="usedQuestion"
                      rules={[
                        {
                          required: true,
                          message: "Please select from where to add questions",
                        },
                      ]}
                    >
                      <Radio.Group onChange={onChange} value={value}>
                        <Space direction="vertical">
                          <Radio value={"allQuestion"}>All Questions</Radio>
                          <Radio value={"unusedQuestion"}>
                            Unused Questions
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Total Time(in min.)"
                      name="quizTime"
                      rules={[
                        {
                          required: quizTimeRequired,
                          message: "Please enter time duration",
                        },
                      ]}
                    >
                      <InputNumber
                        className="w-100"
                        placeholder="Enter Total Time"
                        disabled={!quizTimeRequired}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </StudentPanelWhiteContainer>
      ) : (
        <StudentPanelWhiteContainer>
          <Spin size="large" />
        </StudentPanelWhiteContainer>
      )}
    </>
  );
}

export default CreateCustomQuiz;
