import { Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { StudentPanelWhiteContainer } from "../../../components/styled/Container.styled";
import { IMyGroup } from "../../../types/studentPanelTypes/studentMyGroupType";
import { ISessionDuration } from "../../../types/studentPanelTypes/studentSessionDuration";

type SessionDurationProps = {
  data?: IMyGroup;
};
function SessionDuration(props: SessionDurationProps) {
  const { data } = props;
  const columns: ColumnsType<ISessionDuration> = [
    {
      key: "date",
      title: (
        <div>
          <Typography.Text>Date</Typography.Text>
        </div>
      ),
      dataIndex: "date",
    },
    {
      key: "tutor",
      title: (
        <div>
          <Typography.Text>Tutor</Typography.Text>
        </div>
      ),
      dataIndex: "tutorName",
    },
    {
      key: "sessionDuration",
      title: (
        <div>
          <Typography.Text>Session Duration</Typography.Text>
        </div>
      ),
      dataIndex: "sessionDuration",
      render: (_, record) => {
        return `${record.sessionDuration} min.`;
      },
    },
  ];
  return (
    <>
      <StudentPanelWhiteContainer>
        <Table<ISessionDuration>
          rowKey="id"
          columns={columns}
          dataSource={data?.sessions}
        />
      </StudentPanelWhiteContainer>
    </>
  );
}

export default SessionDuration;
