import { ConfigProvider, Menu, MenuProps, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { AiFillLayout } from "react-icons/ai";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import {
  myLearningItemTextColor,
  myLearningSelectedBgColor,
  sideMenuBgColor,
} from "../../constants/theme";
import { useGetAllMyLearningByCourseIdQuery } from "../../features/studentPanelFeatures/StudentMyLearning/myLearningApiSlice";
import { setArrayPreview, setLearningState } from "../../features/ui/uiSlice";
import { IQuestionType } from "../../types/questionType";
import { ISection } from "../../types/section";
import FileTypeIcon from "../resource/FileTypeIcon";
import ProgressCheckbox from "./ProgressCheckbox";
import { useAppSelector } from "../../app/hooks";

const quizIconStyle = {
  fontSize: "1rem",
  marginLeft: "1rem",
  marginRight: "1rem",
};
function MyLearningSideMenu({ checkMarked, markedData }: any) {
  const idFromLocalStorage = secureLocalStorage.getItem("id")?.toString() || null;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { courseId } = useParams();
  // const [openKeys, setOpenKeys] = useState<string[]>([]);
  const { data, isLoading } = useGetAllMyLearningByCourseIdQuery({
    id: idFromLocalStorage,
    courseId: courseId,
  });
  const myLearning = useAppSelector(
    (state) => state.ui.mylearning
  );

  const menuItemSelectedKey =
    pathname.split("/")[3] === "courseIntro" || "courseIntroResource"
      ? pathname.split("/")[3] + "/" + pathname.split("/")[4]
      : pathname.split("/")[3] +
      "/" +
      pathname.split("/")[4] +
      "/" +
      pathname.split("/")[5] +
      "/" +
      pathname.split("/")[6];
  const onClick: MenuProps["onClick"] = (e) => {
    navigate(`${e.key}`);
  };
  type MenuItem = Required<MenuProps>["items"][number];

  useEffect(() => {
    if (!isLoading) {
      dispatch(setLearningState(data?.data[0]));
      const menuItemsId = data?.data[0]?.course?.section?.map(
        (section: ISection) =>
          section?.lesson?.map((lesson: IQuestionType) =>
            lesson?.topic?.map((topic: any) => [
              topic?.id,
              ...topic?.resources,
              ...topic?.quizzes,
            ])
          )
      );

      const newMenuItems = menuItemsId;

      newMenuItems?.unshift([
        [
          [
            {
              id: `courseIntro/${data?.data[0]?.course?.id}`,
              name: "courseIntro",
            },
          ],
        ],
      ]);

      dispatch(setArrayPreview(newMenuItems));
    }
  }, [data, dispatch, isLoading]);


  useEffect(() => {
    if (myLearning) {
      myLearning?.course?.section?.map((section: any) =>
        section?.lesson?.map((lesson: IQuestionType) =>
          lesson?.topic?.map((topic: any) =>
            [...topic?.resources, ...topic?.quizzes].map((item: any) => {
              if (item.id.toString() === pathname.split("/")[pathname.split("/").length - 1]) {
                if (item.markAsCompletedForResource || item.markAsCompletedForQuiz) {
                  checkMarked(true);
                } else {
                  if (item.id === +markedData?.itemId) {
                    checkMarked(true);
                  } else {
                    checkMarked(false);
                  }
                }
              }
            }
            )
          )
        )

      );
    }
  }, [pathname, checkMarked, data, myLearning, markedData]);

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    className?: string,
    children?: MenuItem[],
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      className
    } as MenuItem;
  }
  const items: MenuProps["items"] = [
    getItem("Introduction", 0, null, "", [
      getItem(
        `Introduction to ${data?.data[0]?.course?.name}`,
        "courseIntro/" + data?.data[0]?.course?.id,
        <BsFillFileEarmarkTextFill style={quizIconStyle} />
      ),
    ]),

    getItem(
      "Course Introduction",
      1,
      null,
      "",
      data?.data[0]?.course?.resources?.map((item) =>
        getItem(
          item?.name,
          "courseIntroResource/" + item?.id,
          <FileTypeIcon resourceType={item?.resourceType} />
        )
      )
    ),

    getItem(
      "Content",
      2,
      null,
      "",
      data?.data[0]?.course?.section?.map((section: any, index) =>
        getItem(
          section?.name,
          section.id,
          null,
          "",
          section?.lesson?.map((lesson: IQuestionType) =>
            lesson?.topic?.map((topic: any) =>
              [...topic?.resources, ...topic?.quizzes].map((item: any) =>
                getItem(
                  item?.name || item?.quizName,
                  `topic/${topic?.id}/` +
                  (item?.quizName ? "quiz/" : "resource/") +
                  item?.id,
                  item?.name ? (
                    <>
                      <ProgressCheckbox
                        topicId={topic?.id}
                        resourceId={item?.id}
                        isChecked={+markedData?.itemId === item.id ? true : item?.markAsCompletedForResource}
                      />
                      <FileTypeIcon resourceType={item?.resourceType} />
                    </>
                  ) : (
                    <>
                      <ProgressCheckbox
                        topicId={topic?.id}
                        quizId={item?.id}
                        isChecked={+markedData?.itemId === item.id ? true : item?.markAsCompletedForQuiz}
                      />
                      <AiFillLayout style={quizIconStyle} />
                    </>
                  ),
                  pathname.split('/')[pathname.split('/').length - 1] === item?.id.toString() ? "ant-menu-item-selected" : "ddddd"
                )
              )
            )
          ).flat(3)
        )
      )
    ),
  ];
  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            radiusItem: 3,
            fontFamily: "HK Grotesk-Medium",
            colorItemTextHover: myLearningItemTextColor,
            colorItemTextSelected: myLearningItemTextColor,
            colorItemBgHover: "white",
            colorItemBgSelected: myLearningSelectedBgColor,
            colorItemText: myLearningItemTextColor,
          },
        },
      }}
    >
      {
        !isLoading ? <Menu
          mode="inline"
          items={items}
          inlineIndent={2}
          onClick={onClick}
          className="preview-sidemenu"
          style={{ backgroundColor: `${sideMenuBgColor}` }}
          defaultOpenKeys={["0"]}
          defaultSelectedKeys={[pathname]}
          selectedKeys={[pathname]}
        /> :
          <Spin style={{ margin: "47%" }} />}
    </ConfigProvider>
  );
}

export default MyLearningSideMenu;
