import { Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useNavigate, useParams } from "react-router-dom";
import { ViewDetailsButton } from "../../../components/styled/Button.styled";
import pageSize from "../../../constants/pageSize";
import { useState } from "react";
import { IBlackBookNotes } from "../../../types/studentPanelTypes/blackBookType";
import { useGetAllStudentNotesQuery } from "../../../features/studentPanelFeatures/StudentQuiz/studentQuizzes";
import getStringFromHTML from "../../../utils/getStringFromHTML";
import secureLocalStorage from "react-secure-storage";
import TextViewer from "../../../components/preview/TextViewer";

function Notes() {
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { courseId } = useParams();
  const { data, isLoading, isFetching } = useGetAllStudentNotesQuery({
    idFromLocalStorage,
    currentPage,
    pageSize,
    courseId,
  });

  const columns: ColumnsType<IBlackBookNotes> = [
    {
      key: "notesName",
      title: (
        <div>
          <Typography.Text>Notes Name</Typography.Text>
        </div>
      ),
      render: (_, record) => {
        return (
          <TextViewer
            textData={record.note.substring(0, 50).concat("...") || ""}
          />
        );
      },
      ellipsis: true,
      width: "15%",
      dataIndex: "note",
    },
    {
      key: "courseName",
      title: (
        <div>
          <Typography.Text>Course</Typography.Text>
        </div>
      ),
      dataIndex: "courseName",
    },
    {
      key: "section",
      title: (
        <div>
          <Typography.Text>Section</Typography.Text>
        </div>
      ),

      dataIndex: "sectionName",
    },
    {
      key: "topic",
      title: (
        <div>
          <Typography.Text> Topic</Typography.Text>
        </div>
      ),

      dataIndex: "topicName",
    },
    {
      key: "date&time",
      title: (
        <div>
          <Typography.Text>Date & Time</Typography.Text>
        </div>
      ),
      render: (dateTime) => (
        <>
          {`${dateTime?.dayOfMonth}/${dateTime?.monthValue}/${dateTime?.year}`}
          &nbsp;,&nbsp;
          {`${dateTime?.hour}:${dateTime?.minute}`}
        </>
      ),
      dataIndex: "dateTime",
    },
    {
      key: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openDetails(record.questionId)} />
      ),
    },
  ];

  const openDetails = (id: number) => {
    navigate(`view-blackbook-question/${id}`);
  };

  return (
    <>
      <Table<IBlackBookNotes>
        dataSource={data?.data?.content}
        rowKey="id"
        columns={columns}
        loading={isLoading || isFetching}
        pagination={{
          pageSize,
          current: currentPage,
          total: data?.data?.totalElements,
          onChange(page) {
            setCurrentPage(page);
          },
        }}
      />
    </>
  );
}

export default Notes;
