import { Select, Form } from "antd";
import { FormInstance, Rule } from "antd/es/form";

import { IValueLabel } from "../../../types/common";

type IProps = {
  label: string;
  name: string;
  rules?: Rule[];
  form: FormInstance;
};

const option: IValueLabel[] = [
  {
    value: "TIME_BASED",
    label: "Time Based",
  },
  {
    value: "PRACTICE_BASED",
    label: "Practice Based",
  },
];
function QuizMode(props: IProps) {
  return (
    <>
      <Form.Item {...props}>
        <Select options={option}  placeholder = "Select Quiz Mode"  />
      </Form.Item>
    </>
  );
}
export default QuizMode;
