import { Col, List, Row, Typography } from "antd";
import { useState } from "react";
import { ViewQuestionButton } from "../../../components/styled/Button.styled";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetAllErrorLogQuery } from "../../../features/studentPanelFeatures/StudentErrorLog/studentErrorLogApiSlice";
import pageSize from "../../../constants/pageSize";
import courseImage from "../../../assets/customIcons/computerIcon.png";
import sectionImage from "../../../assets/customIcons/accessTimePeach.png";
import lessonIcon from "../../../assets/customIcons/widgetIcon.png";
import questionTypeIcon from "../../../assets/customIcons/questionIcon.png";

import { StudentPanelWhiteContainer } from "../../../components/styled/Container.styled";
import QuestionTypesConstant from "../../../utils/constants";
import secureLocalStorage from "react-secure-storage";
import TextViewer from "../../../components/preview/TextViewer";
import { useAppSelector } from "../../../app/hooks";
import { setStudentBreadcrumb } from "../../../features/ui/uiSlice";
import { useDispatch } from "react-redux";

function ErrorLog() {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const idFromLocalStorage =
    secureLocalStorage.getItem("id")?.toString() || null;
  const { data, isLoading, isFetching } = useGetAllErrorLogQuery({
    id: idFromLocalStorage,
    pageSize,
    courseId,
    currentPage,
  });
  const handleClick = (id: number) => {
    navigate(`view-question/${id}`);
  };
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  dispatch(setStudentBreadcrumb([`${pathname.split("/")[3]}`]));

  return (
    <>
      <StudentPanelWhiteContainer>
        <Col span={24}>
          <Row className="mb-1">
            <Typography.Title level={3}>ErrorLog</Typography.Title>
          </Row>
          <Row className="items-row-container">
            <Col span={24}>
              <List
                loading={isLoading || isFetching}
                itemLayout="horizontal"
                dataSource={data?.data.content}
                pagination={{
                  pageSize,
                  current: currentPage,
                  total: data?.data?.totalElements,
                  onChange(page) {
                    setCurrentPage(page);
                  },
                }}
                renderItem={(item, index) => (
                  <List.Item
                    key={index}
                    actions={[
                      <ViewQuestionButton
                        onClick={() => handleClick(item.questionId)}
                      />,
                    ]}
                  >
                    <List.Item.Meta
                      title={
                        <span className="font-weight-400">
                          <TextViewer
                            textData={
                              item.questionText
                                .replace(/(<([^>]+)>)/gi, "")
                                .substring(0, 300)
                                .concat("...") || ""
                            }
                          />
                        </span>
                      }
                      description={[
                        <Row
                          justify={"space-between"}
                          className="description-content-row"
                        >
                          <Col>
                            <Row align={"middle"}>
                              <img src={courseImage} alt="course-img" />
                              &nbsp;
                              <span>{item.courseName}</span>
                            </Row>
                          </Col>
                          <Col>
                            <Row align={"middle"}>
                              <img src={sectionImage} alt="section-img" />
                              &nbsp;
                              <span>{item.sectionName}</span>
                            </Row>
                          </Col>
                          <Col>
                            <Row align={"middle"}>
                              <img src={lessonIcon} alt="lesson-img" />
                              &nbsp;
                              <span>{item.lessonName}</span>
                            </Row>
                          </Col>
                          <Col span={6}>
                            <Row align={"middle"}>
                              <img
                                src={questionTypeIcon}
                                alt="questionType-img"
                              />
                              &nbsp;
                              <span>
                                {item.questionCategory ===
                                `${QuestionTypesConstant.Numerical}`
                                  ? "Numerical Question"
                                  : ""}
                                {item.questionCategory ===
                                `${QuestionTypesConstant.Mcq}`
                                  ? "Multiple Choice Question"
                                  : ""}
                                {item.questionCategory ===
                                `${QuestionTypesConstant.Rc}`
                                  ? "Reading Comprehension"
                                  : ""}
                              </span>
                            </Row>
                          </Col>
                        </Row>,
                      ]}
                    />
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Col>
      </StudentPanelWhiteContainer>
    </>
  );
}

export default ErrorLog;
